/* App.css */
* {
    box-sizing: border-box;
}

/* Font family */
@font-face {
    font-family: "Inter";
    src: local("InterRegular"),
        url("./fonts/Inter-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Passion One";
    src: local("PassionOne"),
        url("./fonts/PassionOne-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

body {
  font-family: "Inter", sans-serif;
}

/* Font styling */
h1,
h2,
h3 {
    font-family: "Passion One";
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
}

header {
    padding: 30px 80px;
    display: flex;
    justify-content: center;
}

h1 {
    font-size: 90px;
}

h2 {
    font-size: 58px;
}

h3 {
    font-size: 35px;
}

p {
    font-size: 18px;
    line-height: 32px;
}

a {
    text-decoration: none;
    color: #3440ab;
}

ul {
    list-style: none;
    display: flex;
}

ul li {
    margin-right: 20px;
}

ul li:last-child {
    margin-right: 0;
}

/* image */
.right img {
    width: 300px;
}

/* Buttons */
.book-now-btn {
    background-color: #ffd84e;
    color: black;
    font-weight: 500;
    padding: 15px 30px;
    border-radius: 40px;
}

.buttons {
    margin-top: 40px;
}

.book-now-btn:hover {
    background-color: #3440ab;
    color: white;
}

.flex-contact {
    margin: 30px 0px 50px 0px;
}

.box-1, .box-2, .box-3, .box-4{
margin-bottom: 20px;
}

.box-1 p, .box-2 p, .box-3 p, .box-4 p{
    font-size: 16px;
    margin:5px 0px;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.pageBackground {
    background-size: 100vw 100vh;
    background-repeat: no-repeat;
    background-image: url("./assets/bg.png");
}
.flex {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 50px 0px;
    grid-template-rows: 120px 120px;
    padding: 50px 0px 50px 0px;
}

.content-width-right p {
    font-size: 12px;
}

.footer-left p{
    font-size:12px;
    margin: 0px;
}

.footer-left{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.footer-links{
    display:flex;
    gap:10px;
}

.footer-links img{
   width:40px;
}

.footer-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
    padding: 60px 80px 30px 80px;
}

@media (min-width: 768px) {
    form input, form select{
        margin-bottom:20px;
    }

    .box-2 {
        max-width: 250px;
    }

    .box-4 {
        max-width: 250px;
    }

    .paddingAbout {
        padding-bottom: 150px;
        width: 350px !important;
    }

    .flex-contact p {
        margin-bottom: 5px;
    }

    .page-content-block {
        max-width: 1200px;
        padding: 40px 200px 80px 200px;
        min-height: 80vh;
    }

    .content-width-booking {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 100px;
        padding: 100px 0px 100px 300px;
    }

    .flex-contact {
        display: flex;
        grid-gap: 60px;
    }

    .maxWidth {
        max-width: 1200px;
    }

    .pawpaw {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        margin-top: -50px;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    h1 {
        font-size: 74px;
    }

    h2 {
        font-size: 48px;
    }

    h3 {
        font-size: 30px;
    }

    p {
        font-size: 16px;
    }
}

@media (max-width: 1024px) {
    h1 {
        font-size: 50px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: 24px;
    }

    p {
        font-size: 16px;
        margin-bottom:0px;
        line-height: 28px;
    }

    .pawpaw {
        display: none;
    }

    .right img {
        width: 250px;
    }

    .page-content-block {
        padding: 50px;
    }

    .header {
        padding: 30px 40px 20px 40px;
    }
}

@media (max-width: 768px) {
    h1 {
        font-size: 70px;
        line-height: 100%;
    }
    .buttons {
        width: 100%;
    }
    .book-now-btn{
        display: flex;
        justify-content: center;
    }
    .hideMobile {
        display: none;
    }

    .mob-column-reverse {
        flex-direction: column-reverse;
        gap: 40px;
    }

    .right {
        display: flex;
        justify-content: center;
    }

    .right img {
        width: 100%;
        max-width: 350px;
        margin: 30px 0px;
    }

    .bookingImageMobile img{
        margin: 30px 0px 0px 0px;
    }
}

@media (min-width: 768px) {
    .hideDesktop {
        display: none;
    }
}

@media (max-width: 490px) {
    .flex {
        grid-gap: unset;
        gap: 60px 30px;
    }

    .footer-container{
        display: flex;
        flex-direction: column;
        align-items: unset;
        padding: 40px;
        gap:10px;
    }
}

.service-title{
    margin-bottom: 0px;
    line-height: 22px;
    margin-top: 10px;
    font-weight: 600;
}
