.landing-content-refer {
    background-size: 100vw 100%;
    background-position: 0;
    background-repeat: no-repeat;
    background-image: url("../../assets/bg.png");
}

.landing-content-testimonials {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.landing-subtitle p:first-child {
    margin-top: 20px;
    font-weight: 800;
    font-size: 20px;
    margin-bottom:10px;
}

.landing-paw {
    position: absolute;
    top: 28%;
    right: 6%;
}

.landing-paw-img{
    width:100px;
}

.no-wrap {
    white-space: no-wrap;
}

.new-line {
    display: block;
}

@media (max-width: 768px){
    #landing {
        color: white;
        background-color: #3440AB;
        position: relative;
        margin-bottom: 25px;
    }
}


@media (min-width: 768px) {

    .landing-content-block {
        display: flex;
        max-width: 1200px;
        align-items: center;
        justify-content: center;
        gap: 80px;
        padding: 100px 80px;
    }
    .landing-subtitle p {
        margin: 0;
    }


    .landing-content-refer {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        padding: 150px 0px 120px 0px;
    }
}

@media (max-width: 1024px) {

    .landing-content-block {
        padding: 50px;
        gap: 40px;
    }

    .landing{
        padding: 70px 50px;
    }

    .testimonialContentBlock{
         padding: 50px 50px 0px 50px;
    }

    .landing-content-testimonials {
        text-align: left;
        display: flex;
        gap: 20px;
    }
    
    .noodle {
        width: 200px;
    }

   .landing-content-refer {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        padding: 130px 70px 50px 70px;
    }
}

@media (max-width: 490px) {
    .landing-content-refer {
        background-size: 105% 50%;
    }
}