/* Form styling */
form select,
form textarea {
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 32px;
    color: black;
}

form input,
form select,
form textarea {
    width: 100%;
    height: 35px;
    padding: 2px 7px;
    margin-bottom: 10px;
}

form textarea {
    width: 100%;
    height: 100px;
}

form div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0px;
}

.form-block {
    /* width: 550px; */
    margin: 70px 0px;
}

.submitButton {
    background-color: #ffd84e;
    margin-top: 20px; 
    cursor: pointer;
    color: black;
    font-weight: 500;
    padding: 15px 30px;
    border-radius: 40px;
    border: none;
    max-width: 200px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    &:disabled {
        background: #ededed;
        cursor: none;
        color: #666666;
    }
}

select:focus-visible,
textarea:focus-visible {
    outline: #3440ab auto 1px;
}



/* Responsive */
@media (min-width: 768px) {
    form input, form select{
        margin-bottom:20px;
    }

   .firstrow {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
    
    .firstrow div {
        width: 50%;
    }
}
