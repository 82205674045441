/* testimonial */
.testimonial-block {
    width: 300px;
    background: #f7f7f7;
    padding: 50px 30px;
    border-radius: 30px;
}

.testimonial-dog-info h3 {
    color: #3440ab;
}

.testimonial-width-left {
    display: flex;
    gap: 30px;
    align-items: center;
}
.testimonial-dog-info {
    text-align: left;
}

.testimonial-dog-info p {
    margin: 0px;
}

.testimonial-dog-info img {
    margin: 5px 0px;
}

.testimonial-dog-description {
    text-align: left;
}


@media (max-width: 1024px) {
    .testimonial-section {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}


@media (max-width: 768px) {
    .testimonial-block p {
        font-size: 14px;
        line-height: 28px;
    }
}



@media (min-width: 768px) {
    .testimonial-section {
        display: flex;
        gap: 50px;
        margin-top: 20px;
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media (min-width: 1024px){
    .testimonialContent {
        padding: 0px 80px;
    }
}