/* Navigation */

nav .book-now-btn:hover {
    background-color: #ffd84e;
    color: black;
}

nav .book-now-btn {
    background-color: #3440ab;
    color: #fff;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    flex: 1;
}

nav ul {
    gap: 30px;
}

nav a {
    font-weight: 400;
    color: black;
}

.link.active{
    color:#3440ab;
}

@media (max-width: 700px) {

.desktopNav {
        display: none;
    }

}

@media (min-width: 768px) {
    .logo img {
        width: 200px;
    }
}
@media (min-width: 699px) {
.menuButton {
        display: none;
    }

    .closeButton{
      display: none;
    }
    .fullScreen{
      display: none;
    }
}

.unstyledButton {
    border: none;
    background-color: unset;
    cursor: pointer;
}

.bookNowLi {
    min-width: 140px;
}

.desktopNavUl {
    gap: 30px;
    align-items: center;
}

.mobileDropdownMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.nav {
    @media (max-width: 479px) {
        display: flex;
        justify-content: space-between;
    }
}

.header {
    padding: 30px 80px;
    @media (max-width: 479px) {
        padding: unset;
        display: flex;
        flex-direction: column;
    }
}

.closeButton {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    font-size: 2rem;
}

.preventScroll {
    overflow: hidden;
    height: 100%;
}

@keyframes exit {
    0% {
        top: 84px;
        bottom: 0px;
    }

    100% {
        top: -100%;
    }
}

@keyframes dropdown {
    0% {
        top: -100%;
    }

    100% {
        top: 84px;
        bottom: 0px;
    }
}

.fullScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 74px;
    bottom: 0;
    left: 0;
    /* transition: all 0.3s ease-in-out 0s; */
    opacity: 1;
    /* animation: dropdown 250ms ease-in-out; */
    overflow: hidden;
    z-index: 1;
}


.mobileNavLinks {
    padding: 10px 30px;
    list-style-type: none;
    text-align: center;
}

.mobileNavLinks li {
    padding: 20px 0px;
}

.bookNowLi {
    margin: 10px 0px;
}

